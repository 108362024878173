var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.multipleChoices, function (multipleChoice, index) {
    return _c('v-row', {
      key: index,
      staticClass: "pl-3"
    }, [_c('v-text-field', {
      class: _vm.readonly ? 'mr-3' : '',
      attrs: {
        "readonly": _vm.readonly,
        "label": "Svaralternativ ".concat(multipleChoice.alternativeNumber),
        "rules": _vm.validateNotEmpty,
        "data-cy": "questionMultipleChoice"
      },
      on: {
        "change": _vm.emitUpdate
      },
      model: {
        value: multipleChoice.description,
        callback: function callback($$v) {
          _vm.$set(multipleChoice, "description", $$v);
        },
        expression: "multipleChoice.description"
      }
    }), !_vm.readonly ? _c('v-btn', {
      staticClass: "mr-3 mt-5",
      attrs: {
        "data-cy": "deleteMultipleChoice",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteMultipleChoice(index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()], 1);
  }), !_vm.readonly ? _c('v-row', {
    staticClass: "pl-3 py-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "data-cy": "addMultipleChoice"
    },
    on: {
      "click": _vm.addMultipleChoice
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Legg til nytt svaralternativ ")], 1)], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }