
import { api } from "@/api/api";
import {
  ApiCreateApplicationFormTemplateDto,
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiGetCourseRegulationFileDto,
} from "@/api/generated/Api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { QuestionValueType } from "@/shared/types/questions.types";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import { Component } from "vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import BaseCard from "@/components/shared/BaseCard.vue";
import ConditionalAdditionalQuestion from "@/components/administration/application-form/ConditionalAdditionalQuestion.vue";
import MultipleChoiceAdditionalQuestion from "@/components/administration/application-form/MultipleChoiceAdditionalQuestion.vue";

enum PageMode {
  Create = "create",
  Display = "display",
}

type ApiJToken = ApiJToken[];

interface ApplicationFormSwitchesConfig {
  propName: keyof ApiCreateApplicationFormTemplateDto;
  label: string;
  description: string[];
  dependOnProp?: keyof ApiCreateApplicationFormTemplateDto;
}

export default defineComponent({
  name: "ApplicationFormDisplayCreatePage",
  components: { BaseCard, ConditionalAdditionalQuestion, MultipleChoiceAdditionalQuestion },
  setup(props, { refs }) {
    const store = useStore<StoreState>();
    const applicationFormModel: Ref<ApiCreateApplicationFormTemplateDto> = ref<ApiCreateApplicationFormTemplateDto>({
      ...getEmptyApplicationFormModel(),
    });
    const courseRegulationsFiles = ref<ApiGetCourseRegulationFileDto[]>([]);
    const route = useRoute();
    const router = useRouter();
    const pageMode = ref(route.params.applicationId ? PageMode.Display : PageMode.Create);
    const existingApplicationFormId = ref(route.params.applicationId);
    // TODO route get if if not then in create mode

    const submitForm = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Påmeldingsskjemaet har valideringsfeil som må rettes opp før det kan lagres"
        );
        return;
      }
      if (!isValidJToken(applicationFormModel.value.additionalQuestions)) {
        openNotification(store, NotificationItemType.Error, "Tilleggsspørsmål kan ikke lagres");
        return;
      }
      if (pageMode.value === PageMode.Create) {
        addApplicationForm();
      }
    };

    const addApplicationForm = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.appformtemplate.createAppFormTemplateAsync(applicationFormModel.value);
        openNotification(store, NotificationItemType.Success, "Påmeldingsskjemaet er lagret");
        goToListOfApplications();
      });
    };

    const goToListOfApplications = () => {
      router.push({
        name: AdminRouteNames.ApplicationsList,
      });
    };

    const isDisabled = (element: ApplicationFormSwitchesConfig) => {
      if (pageMode.value === PageMode.Display) {
        return true;
      }
      if (!element.dependOnProp) {
        return false;
      }
      return !applicationFormModel.value[element.dependOnProp];
    };

    const onChangeElementConfig = (element: ApplicationFormSwitchesConfig) => {
      if (!applicationFormModel.value[element.propName]) {
        applicationFormModel.value = {
          ...applicationFormModel.value,
          ...getUpdatedDependOnPropValues(element, false),
        };
      }
    };

    /** Return an object of recursively updated dependent values */
    const getUpdatedDependOnPropValues = (
      element: ApplicationFormSwitchesConfig,
      value: boolean,
      level = 0
    ): Record<string, boolean> =>
      applicationFormSwitchesConfig.reduce<Record<string, boolean>>((acc, config) => {
        if (level > 10) {
          throw new Error("Recursion limit exceeded");
        }
        if (config.dependOnProp === element.propName) {
          return {
            ...acc,
            ...getUpdatedDependOnPropValues(config, value, level + 1),
            [config.propName]: value,
          };
        }
        return acc;
      }, {});

    const addQuestion = () => {
      if (!applicationFormModel.value.additionalQuestions) {
        applicationFormModel.value.additionalQuestions = [];
      }
      applicationFormModel.value.additionalQuestions.push(
        getInitialQuestionData(applicationFormModel.value.additionalQuestions.length + 1)
      );
    };

    const updateQuestionValue = (id: string, props: Record<string, unknown>) => {
      if (!applicationFormModel.value.additionalQuestions) {
        return;
      }
      const questionIndex = applicationFormModel.value.additionalQuestions.findIndex((question) => question.id === id);
      const questions = applicationFormModel.value.additionalQuestions;
      questions.splice(questionIndex, 1, {
        ...questions[questionIndex],
        ...props,
      });
    };

    const deleteQuestion = (selectedQuestion: ApiApplicationFormTemplateAdditionalQuestionDto, index: number) => {
      applicationFormModel.value.additionalQuestions?.splice(index, 1);
      applicationFormModel.value.additionalQuestions?.map((question, index) => {
        question.questionNumber = index + 1;
        if (
          selectedQuestion.type === QuestionValueType.Boolean &&
          selectedQuestion.id === question.displayIfQuestionIdIsTrue
        ) {
          question.displayIfQuestionIdIsTrue = undefined;
        }
      });
    };

    const getLabelFromPropName = (propName: keyof ApiCreateApplicationFormTemplateDto) => {
      const element = applicationFormSwitchesConfig.find((element) => element.propName === propName);
      if (!element) {
        return "Ukjent";
      }
      return element.label;
    };

    const goToApplicationsList = () => {
      router.push({
        name: AdminRouteNames.ApplicationsList,
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        if (pageMode.value === PageMode.Display) {
          const applicationForm = (await api.appformtemplate.getAppFormTemplateAsync(existingApplicationFormId.value))
            .data;
          applicationFormModel.value = applicationForm;
        }
        courseRegulationsFiles.value = (await api.file.getCourseRegulationsFilesAsync()).data;
      });
    });

    return {
      applicationFormModel,
      submitForm,
      readonly: computed(() => pageMode.value === PageMode.Display),
      pageMode,
      PageMode,
      applicationFormSwitchesConfig,
      isDisabled,
      courseRegulationsFiles,
      getLabelFromPropName,
      goToApplicationsList,
      QuestionValueType,
      questionOptionItems,
      questionDataComponent,
      addQuestion,
      deleteQuestion,
      validateNotEmpty,
      updateQuestionValue,
      onChangeElementConfig,
    };
  },
});

const questionOptionItems: { value: QuestionValueType; text: string }[] = [
  {
    value: QuestionValueType.Boolean,
    text: "Ja/nei",
  },
  {
    value: QuestionValueType.Text,
    text: "Tekstfelt",
  },
  {
    value: QuestionValueType.MultipleChoice,
    text: "Svaralternativer",
  },
];

const questionDataComponent: { [type in QuestionValueType]?: Component } = {
  [QuestionValueType.Text]: ConditionalAdditionalQuestion,
  [QuestionValueType.MultipleChoice]: ConditionalAdditionalQuestion,
};

const getInitialQuestionData = (questionNumber: number) => ({
  id: generateUuid(),
  questionNumber,
  type: QuestionValueType.Text,
  description: "",
  required: true,
});

/** Verify that an object can be serialized to JSON (ApiJToken is a backend-specific JSON document type) */
const isValidJToken = (value?: any): value is ApiJToken => {
  try {
    JSON.stringify(value);
  } catch (e) {
    return false;
  }
  return true;
};

const applicationFormSwitchesConfig: ApplicationFormSwitchesConfig[] = [
  {
    propName: "isCitizenship",
    label: "Statsborgerskap",
    description: ["Utenlandsk (land må velges fra liste)"],
  },
  { propName: "isPersonalNumber", label: "Fødselsnummer", description: ["Fylle ut Fødselsnummer eller D-nummer"] },
  { propName: "isNorwegianMotherTongue", label: "Norsk morsmål", description: ["Ja", "Nei"] },
  {
    propName: "isNorwegianCertificationLevel",
    label: "Norskferdigheter, CEFR-nivå",
    description: ["Liste med nivåene (A1, A2, B1, B2, C1, C2)"],
    dependOnProp: "isNorwegianMotherTongue",
  },
  {
    propName: "isNorwegianSkills",
    label: "Norskferdigheter",
    description: ["Mindre bra", "Bra", "Svært bra"],
    dependOnProp: "isNorwegianMotherTongue",
  },
  {
    propName: "isCompetence",
    label: "Kompetanse",
    description: ["Formell kompetanse", "Realkompetanse"],
  },
  {
    propName: "isEmployer",
    label: "Arbeidsgiver",
    description: [
      "Organisasjonsnummer",
      "Enhets-/virksomhetsnavn",
      "E-post til nærmeste overordnet",
      "Adresse",
      "Postnr./-sted",
      "Mobilnummer",
    ],
  },
  {
    propName: "isEmployerEmail",
    label: "E-post til nærmeste overordnet",
    description: [],
    dependOnProp: "isEmployer",
  },
  {
    propName: "isCostBearer",
    label: "Oppgi fakturaadresse",
    description: ["Vil bli bedt om å søke med orgnummer eller om det er privat."],
  },
  {
    propName: "isInvoiceReference",
    label: "Fakturareferanse",
    description: ["Vil bli bedt om å søke med orgnummer eller om det er privat."],
    dependOnProp: "isCostBearer",
  },
  {
    propName: "isInstallments",
    label: "Avdragsbetaling",
    description: ["Månedlig", "Kvartalsvis", "Halvårlig"],
    dependOnProp: "isCostBearer",
  },
  {
    propName: "isMonthly",
    label: "Avdragsbetaling Månedlig",
    description: [],
    dependOnProp: "isInstallments",
  },
  {
    propName: "isQuarterly",
    label: "Avdragsbetaling Kvartalsvis",
    description: [],
    dependOnProp: "isInstallments",
  },
  {
    propName: "isSemiAnnually",
    label: "Avdragsbetaling Halvårlig",
    description: [],
    dependOnProp: "isInstallments",
  },
  {
    propName: "isNewsletter",
    label: "AOF Nyhetsbrev",
    description: ["Avmerkingsboks for å motta nyhetsbrev fra AOF"],
  },
  {
    propName: "isMemberOrganization",
    label: "Medlemsorganisasjoner",
    description: ["Liste hvor det kan velges kun én medlemsorganisasjon"],
  },
  {
    propName: "isRegionalOffices",
    label: "Regionkontor",
    description: ["Liste som viser regionkontor pr hovedkontor"],
    dependOnProp: "isMemberOrganization",
  },
];

const getEmptyApplicationFormModel: () => ApiCreateApplicationFormTemplateDto = () => ({
  name: "",
  isVocationalSchool: false,
  isCitizenship: false,
  isPersonalNumber: false,
  isNorwegianMotherTongue: false,
  isNorwegianCertificationLevel: false,
  isNorwegianSkills: false,
  isCompetence: false,
  isEmployer: false,
  isEmployerEmail: false,
  isCostBearer: false,
  isInstallments: false,
  isMonthly: false,
  isSemiAnnually: false,
  isQuarterly: false,
  isInvoiceReference: false,
  isNewsletter: false,
  isUnionized: true,
  isMemberOrganization: false,
  isRegionalOffices: false,
  regulationFileId: null,
  additionalQuestions: [],
});
