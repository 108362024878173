
import {
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiApplicationFormTemplateAdditionalQuestionAlternativeDto,
} from "@/api/generated/Api";
import { defineComponent, onMounted, ref, PropType } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";

export default defineComponent({
  name: "MultipleChoiceAdditionalQuestion",
  props: {
    question: {
      type: Object as PropType<ApiApplicationFormTemplateAdditionalQuestionDto>,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const multipleChoices = ref<ApiApplicationFormTemplateAdditionalQuestionAlternativeDto[]>([]);

    const addMultipleChoice = () => {
      multipleChoices.value.push(getInitialMultipleChoiceData(multipleChoices.value.length + 1));
    };

    const deleteMultipleChoice = (index: number) => {
      multipleChoices.value?.splice(index, 1);
      multipleChoices.value?.map((multipleChoice, index) => {
        multipleChoice.alternativeNumber = index + 1;
      });
    };

    const emitUpdate = () => {
      emit("update", props.question.id, {
        questionAlternatives: multipleChoices.value,
      });
    };

    onMounted(() => {
      if (props.readonly) {
        multipleChoices.value = props.question.questionAlternatives ?? [];
      } else {
        addMultipleChoice();
      }
    });

    return {
      multipleChoices,
      validateNotEmpty,
      emitUpdate,
      addMultipleChoice,
      deleteMultipleChoice,
    };
  },
});

const getInitialMultipleChoiceData = (alternativeNumber: number) => ({
  alternativeNumber,
  description: "",
});
