var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('BaseCard', {
    staticClass: "mt-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm.pageMode === _vm.PageMode.Create ? _c('span', [_vm._v("Ny applikasjon")]) : _vm._e(), _vm.pageMode === _vm.PageMode.Display ? _c('span', {
          attrs: {
            "data-cy": "name"
          }
        }, [_vm._v("Viser: " + _vm._s(_vm.applicationFormModel.name))]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "header-actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "text--primary",
          attrs: {
            "data-cy": "cancel",
            "color": "white"
          },
          on: {
            "click": _vm.goToApplicationsList
          }
        }, [_vm._v("Avbryt")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "primary": ""
          },
          on: {
            "click": _vm.goToApplicationsList
          }
        }, [_vm._v("Avbryt")]), !_vm.readonly ? _c('v-btn', {
          staticClass: "px-6",
          attrs: {
            "color": "primary",
            "data-cy": "application-save"
          },
          on: {
            "click": _vm.submitForm
          }
        }, [_vm._v(" Lagre ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    attrs: {
      "data-cy": "info"
    }
  }, [_vm._v("Informasjon som allerede er samlet inn om brukeren:")]), _c('ul', [_c('li', [_vm._v("Fornavn")]), _c('li', [_vm._v("Etternavn")]), _c('li', [_vm._v("Fødselsdato")]), _c('li', [_vm._v("Telefonnummer")]), _c('li', [_vm._v("Kjønn")]), _c('li', [_vm._v("Postadresse")])])])], 1), _c('BaseCard', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Navn ")];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mt-5",
    attrs: {
      "data-cy": "vocationalSchool"
    }
  }, [_vm._v("Er dette skjemaet for fagskolen?")]), _c('v-switch', {
    staticClass: "mr-5 ml-10",
    attrs: {
      "label": _vm.applicationFormModel.isVocationalSchool ? 'Ja' : 'Nei',
      "disabled": _vm.readonly,
      "color": "primary",
      "data-cy": "vocationalSchoolToggle",
      "hide-details": "",
      "inset": ""
    },
    model: {
      value: _vm.applicationFormModel.isVocationalSchool,
      callback: function callback($$v) {
        _vm.$set(_vm.applicationFormModel, "isVocationalSchool", $$v);
      },
      expression: "applicationFormModel.isVocationalSchool"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Vennligst gi ".concat(_vm.applicationFormModel.isVocationalSchool ? 'søknadskjemaet' : 'påmeldingsskjemaet', " et navn"),
      "rules": _vm.validateNotEmpty,
      "data-cy": "applicationFormName",
      "required": "",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.applicationFormModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.applicationFormModel, "name", $$v);
      },
      expression: "applicationFormModel.name"
    }
  })], 1)], 1)], 1), _c('BaseCard', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Konfigurasjon ")];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.applicationFormSwitchesConfig, function (currentElement) {
    return _c('v-col', {
      key: currentElement.propName,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', [_c('v-tooltip', {
      attrs: {
        "right": "",
        "color": "warning",
        "disabled": !_vm.isDisabled(currentElement) || _vm.readonly
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-switch', {
            attrs: {
              "label": currentElement.label,
              "disabled": _vm.isDisabled(currentElement),
              "data-cy": "".concat(currentElement.propName, "Toggle"),
              "color": "primary",
              "inset": ""
            },
            on: {
              "change": function change($event) {
                return _vm.onChangeElementConfig(currentElement);
              }
            },
            model: {
              value: _vm.applicationFormModel[currentElement.propName],
              callback: function callback($$v) {
                _vm.$set(_vm.applicationFormModel, currentElement.propName, $$v);
              },
              expression: "applicationFormModel[currentElement.propName]"
            }
          })], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Kan bare velges hvis " + _vm._s(_vm.getLabelFromPropName(currentElement.dependOnProp)) + " er aktiv")])]), currentElement.description.length > 0 ? _c('ul', [_c('v-row', _vm._l(currentElement.description, function (description, index) {
      return _c('li', {
        key: index,
        staticClass: "mr-5 ml-10 mt-5"
      }, [_vm._v(" " + _vm._s(description) + " ")]);
    }), 0)], 1) : _vm._e()], 1)], 1);
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.courseRegulationsFiles,
      "label": "Kursbestemmelse",
      "item-text": "description",
      "item-value": "id",
      "data-cy": "courseRegulations",
      "disabled": _vm.readonly,
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.applicationFormModel.regulationFileId,
      callback: function callback($$v) {
        _vm.$set(_vm.applicationFormModel, "regulationFileId", $$v);
      },
      expression: "applicationFormModel.regulationFileId"
    }
  })], 1)], 1)], 2), _c('BaseCard', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Tilleggsspørsmål ")];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.applicationFormModel.additionalQuestions, function (question, index) {
    return _c('v-card', {
      key: question.id,
      staticClass: "mt-5 mb-5",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', {
      attrs: {
        "data-cy": "questionTitle"
      }
    }, [_vm._v(" Spørsmål " + _vm._s(question.questionNumber) + " "), _c('v-spacer'), !_vm.readonly ? _c('v-btn', {
      attrs: {
        "data-cy": "delete",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteQuestion(question, index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-select', {
      attrs: {
        "label": "Spørsmålstype",
        "items": _vm.questionOptionItems,
        "data-cy": "questionType",
        "readonly": _vm.readonly
      },
      model: {
        value: question.type,
        callback: function callback($$v) {
          _vm.$set(question, "type", $$v);
        },
        expression: "question.type"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": "Spørsmålstekst",
        "rules": _vm.validateNotEmpty,
        "data-cy": "questionText",
        "readonly": _vm.readonly
      },
      model: {
        value: question.description,
        callback: function callback($$v) {
          _vm.$set(question, "description", $$v);
        },
        expression: "question.description"
      }
    }), question.type === _vm.QuestionValueType.MultipleChoice ? _c('MultipleChoiceAdditionalQuestion', {
      attrs: {
        "question": question,
        "readonly": _vm.readonly
      },
      on: {
        "update": _vm.updateQuestionValue
      }
    }) : _vm._e(), question.type in _vm.questionDataComponent ? _c('ConditionalAdditionalQuestion', {
      attrs: {
        "question": question,
        "questionData": _vm.applicationFormModel.additionalQuestions,
        "questionNumber": question.questionNumber,
        "readonly": _vm.readonly
      },
      on: {
        "update": _vm.updateQuestionValue
      }
    }) : _vm._e(), _c('v-switch', {
      staticClass: "pl-1",
      attrs: {
        "label": "Sp\xF8rsm\xE5let er ".concat(question.required ? 'obligatorisk' : 'valgfritt'),
        "disabled": _vm.readonly,
        "color": "primary",
        "data-cy": "questionRequiredToggle",
        "hide-details": "",
        "inset": ""
      },
      model: {
        value: question.required,
        callback: function callback($$v) {
          _vm.$set(question, "required", $$v);
        },
        expression: "question.required"
      }
    })], 1)], 1);
  }), !_vm.readonly ? _c('div', {
    staticClass: "mt-5 mb-5"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "outlined": "",
      "large": "",
      "data-cy": "additionalQuestion"
    },
    on: {
      "click": _vm.addQuestion
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" Legg til nytt tilleggsspørsmål ")], 1)], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }