var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.questionReferences && _vm.questionReferences.length ? _c('div', [_c('v-select', {
    attrs: {
      "label": "Visning av spørsmål",
      "items": _vm.questionReferenceOptions,
      "readonly": _vm.readonly,
      "data-cy": "displayIfQuestionIdIsTrue"
    },
    on: {
      "change": _vm.emitUpdate
    },
    model: {
      value: _vm.displayIfQuestionIdIsTrue,
      callback: function callback($$v) {
        _vm.displayIfQuestionIdIsTrue = $$v;
      },
      expression: "displayIfQuestionIdIsTrue"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }